<template>
  <div id="app">
    <Menubar :model="menuItems" />
    <!-- <p>
      <router-link to="/">Home</router-link>
      <router-link to="/login">Login</router-link>
    </p> -->
    <!-- route outlet -->
    <!-- component matched by the route will render here -->
    <router-view></router-view>
  </div>
</template>

<script>
import Menubar from "primevue/menubar";
import { ref } from "vue";
export default {
  components: {},
  setup() {
    const menuItems = ref([]);

    menuItems.value = [
      {
        label: "Home",
        icon: "pi pi-home",
        to: "/",
      },
      {
        label: "Login",
        icon: "pi pi-sign-in",
        to: "/login",
      },
    ];
    return {
      Menubar,
      menuItems,
    };
  },
};
</script>

<style scoped>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 10px;
}
.p-menubar {
  margin-left: 0.4em;
}
body #app .p-button {
  margin-left: 0.4em;
}
</style>
