<template>
  <Card style="width: 25rem; margin: 2em">
    <template #title> Goh Education </template>
    <template #content>
      <span class="p-float-label p-mt-2 p-mb-4">
        <InputText id="username" type="text" v-model="username" />
        <label for="username">Username</label>
      </span>

      <span class="p-float-label p-mt-2 p-mb-2">
        <Password v-model="password" :feedback="false" />

        <!-- <InputText
          id="password"
          type="text"
          v-model="password"
          class="p-mt-2 p-mb-2"
        /> -->
        <label for="password">Password</label>
      </span>
    </template>

    <template #footer>
      <Button
        type="submit"
        label="Login"
        @click="login()"
        class="p-button-raised"
      />
    </template>
  </Card>
</template>

<script>
import axios from "axios";
import InputText from "primevue/inputtext";
import Password from "primevue/password";

import Card from "primevue/card";
import Button from "primevue/button";
import { ref } from "vue";
import { useRouter } from "vue-router";
export default {
  setup() {
    const username = ref("");
    const password = ref("");
    const route = useRouter();

    function login() {
      // const api = "http://localhost:4040/api/user/login";
      const api = "https://api.goheducation.com.my/api/user/login";

      axios
        .post(api, {
          user: { username: username.value, password: password.value },
        })
        .then((response) => {
          const token = "Token " + response.data.user.token;
          // const user = response.data.user
          localStorage.setItem("token", token);
          axios.defaults.headers.common["Authorization"] = token;
          // this.$router.push({ path: "/home" });
          route.push({ path: "/" });
        });
    }
    return { username, password, InputText, Button, login, Card, Password };
  },
};
</script>