import { createApp } from 'vue'
import App from './App.vue'
import router from './router'


import PrimeVue from 'primevue/config';
import Button from 'primevue/button';
// import DataTable from 'primevue/datatable';
// import InputText from 'primevue/inputtext';

// import Toast from 'primevue/toast';
// import ToastService from 'primevue/toastservice';


// import 'primevue/resources/themes/saga-orange/theme.css';
import './assets/mdc-light/theme.css'; // copied from ~primevue/resources/themes
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

const app = createApp(App);

app.use(PrimeVue);
app.use(router)

// app.use(ToastService);
// app.component('Toast', Toast);
// app.component('InputText', InputText);
app.component('Button', Button);
// app.component('DataTable', DataTable);

app.mount('#app')