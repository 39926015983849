
import Home from "@/views/Home.vue"
import Login from "@/components/Login.vue"
import { createRouter, createWebHashHistory } from 'vue-router'


const routes = [
    {
        path: '/', name: Home, component: Home,
    },
    { path: '/login', name: Login, component: Login },
]


const router = createRouter({
    // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
    history: createWebHashHistory(),
    routes, // short for `routes: routes`
})

// router.beforeEach((next) => {
//     // canUserAccess() returns `true` or `false`
//     if (localStorage.getItem("token") == null) {
//         next({ name: 'Login' })
//     } next()
// })


export default router