<template>
  <!-- <div class="app-container"> -->
  <div class="card">
    <DataTable
      :value="employees"
      ref="dt"
      v-model:expandedRows="expandedRows"
      dataKey="id"
      :paginator="true"
      :rows="10"
      v-model:filters="filters1"
      v-model:selection="selectedCandidate"
      selectionMode="single"
      @row-select="onRowSelect"
      @row-unselect="onRowUnselect"
      filterDisplay="menu"
      :loading="loading"
      responsiveLayout="scroll"
      stripedRows
      :globalFilterFields="[
        'firstname',
        'lastname',
        'chinesename',
        'cellphone',
      ]"
      sortField="applieddate"
      :sortOrder="-1"
    >
      <template #header>
        <Button
          class="p-mr-4 p-pr-4"
          icon="pi pi-external-link"
          label="Export"
          @click="exportCSV()"
        />
        <Button
          class="p-mr-4 p-pr-4"
          icon="pi pi-minus"
          label="Collapse All"
          @click="collapseAll"
        />
        <Button
          type="button"
          icon="pi pi-filter-slash"
          label="Clear"
          class="p-button-outlined p-mr-4 p-pr-4"
          @click="clearFilter()"
        />

        <span class="p-input-icon-left p-mr-4 p-pr-4">
          <i class="pi pi-search" />
          <InputText
            v-model="filters1['global'].value"
            placeholder="Keyword Search"
          />
        </span>
        <div class="table-header-container p-mt-4">
          <MultiSelect
            class="p-mr-4 p-pr-4"
            v-model="selectedColumns"
            :options="hideColNames"
            optionLabel="colName"
            placeholder="Hide Columns"
            display="chip"
            @update:modelValue="hideColumns"
          />
        </div>
        <!-- <div class="table-header-container p-mt-4">
          <div class="p-grid">
            <div class="p-col"></div>

            <div class="p-col"></div>

            <div class="p-col"></div>
          </div>
        </div> -->
      </template>
      <template #empty> No candidates found. </template>
      <template #loading> Loading candidates data. Please wait. </template>
      <Column
        field="applieddate"
        header="Applied"
        filterField="appliedDate"
        dataType="date"
        sortable
      >
        <template #body="slotProps">
          <span>
            {{ formatDate(slotProps.data.applieddate) }}
          </span>
        </template>
        <template #filter="{ filterModel }">
          <Calendar
            v-model="filterModel.value"
            dateFormat="dd/mm/yy"
            placeholder="dd/mm/yyyy"
          />
        </template>
      </Column>
      <Column
        style="min-width: 8rem"
        field="interviewinfo.contacted"
        header="Contacted"
      >
        <template #body="{ data }">
          <span v-if="data.interviewinfo.contacted == false">
            <Badge value="No"></Badge>
          </span>

          <span v-if="data.interviewinfo.contacted == true">
            <Badge :value="data.interviewinfo.ratings"></Badge>
          </span>
        </template>
      </Column>
      <Column
        :style="{ display: firstShow }"
        field="firstname"
        header="Firstname"
      >
      </Column>

      <Column field="lastname" header="Lastname"></Column>

      <Column :expander="true" headerStyle="width: 1rem"></Column>
      <Column
        field="position"
        header="Position"
        filterField="position"
        :filterMenuStyle="{ width: '14rem' }"
        :showFilterMatchModes="false"
        style="min-width: 12rem"
      >
        <template #filter="{ filterModel }">
          <Dropdown
            v-model="filterModel.value"
            :options="positionTypes"
            placeholder="Any"
            class="p-column-filter"
            :showClear="true"
          >
          </Dropdown>
        </template>
      </Column>

      <Column
        :style="{ display: chiNameShow }"
        field="chinesename"
        header="中文名"
        headerStyle="min-width: 5rem"
      ></Column>
      <Column field="cellphone" header="Cellphone"></Column>
      <Column
        :style="{ display: genderShow }"
        field="gender"
        header="性别"
      ></Column>
      <Column field="age" header="Age" sortable> </Column>

      <Column field="requestedsalary" header="Salary" sortable></Column>

      <Column field="workexperience" header="工作经验"></Column>
      <!-- <Column field="transport"></Column> -->
      <Column header="运输">
        <template #body="slotProps">
          <span v-if="slotProps.data.transport == true"> Yes </span>
          <span v-else> No </span>
        </template>
      </Column>
      <Column field="education" header="Education"></Column>

      <Column
        field="speakchinese"
        filterField="speakchinese"
        header="Speaks Chinese"
        :showFilterMatchModes="false"
      >
        <template #filter="{ filterModel }">
          <Dropdown
            v-model="filterModel.value"
            :options="ChineseSpeakers"
            optionLabel="Type"
            optionValue="Value"
            placeholder="Any"
            class="p-column-filter"
            :showClear="true"
          >
          </Dropdown>
        </template>
      </Column>
      <Column
        :style="{ display: rwChineShow }"
        field="readwritechinese"
        header="R/W Chinese"
      ></Column>
      <Column field="address.taman" header="Taman">
        <template #body="slotProps">
          <span>
            <a
              :href="
                'https://www.google.com/maps/search/' +
                slotProps.data.address.taman
              "
              >{{ slotProps.data.address.taman }}</a
            >
          </span>
        </template>
      </Column>
      <Column
        :style="{ display: availabilityShow }"
        field="availability"
        header="Availability"
      >
        <template #body="slotProps">
          <span>
            {{ formatDate(slotProps.data.availability) }}
          </span>
        </template>
      </Column>
      <Column
        :style="{ display: compShow }"
        field="computerskills"
        header="Computer Skills"
      ></Column>

      <Column
        :style="{ display: workShow }"
        field="workingtime"
        header="Work Time"
      ></Column>

      <Column :style="{ display: delShow }" :exportable="false">
        <template #body="slotProps">
          <Button
            icon="pi pi-trash"
            class="p-button-rounded p-button-danger"
            @click="DeleteCandidate(slotProps.data.id)"
          />
        </template>
      </Column>

      <!-- <template #body="slotProps">
          <span v-if="slotProps.data.position != 'Admin/Clerk'"></span>
        </template> -->

      <template #expansion="slotProps" :exportable="true">
        <div class="orders-subtable">
          <h5>Teachers Info</h5>
          <table>
            <tr>
              <th>马来语</th>
              <th>英语</th>
              <th>英语</th>
              <th>中国实力</th>
              <th>英语能力</th>
              <th>马来实力</th>
              <th>数学实力</th>
              <th>科学实力</th>
              <th>MOE Licence</th>
            </tr>
          </table>
          <DataTable :value="[slotProps.data.teachinginfo]">
            <Column field="chineselanguage"></Column>
            <Column field="englishlanguage"></Column>
            <Column field="malaylanguage"></Column>
            <Column field="chinesestrenght"></Column>
            <Column field="englishstrenght"></Column>
            <Column field="malaystrenght"></Column>
            <Column field="mathstrenght"></Column>
            <Column field="sciencestrenght"></Column>
            <Column field="moelicence"></Column>
          </DataTable>
        </div>
      </template>
    </DataTable>
  </div>
  <Dialog v-model:visible="interviewDisplay" :style="{ width: '50vw' }">
    <template #header>
      <h3>Interview Form</h3>
    </template>
    <div class="p-fluid">
      <div class="p-field">
        <label for="interviewCommentBox">Comments</label>
        <Textarea
          :class="{ 'p-invalid': interviewError }"
          id="interviewCommentBox"
          v-model="candidate.interviewinfo.comments"
          :autoResize="true"
          rows="5"
          cols="40"
        />
      </div>
      <div class="p-field">
        <Dropdown
          :class="{ 'p-invalid': interviewError }"
          v-model="candidate.interviewinfo.ratings"
          :options="interviewRatingOptions"
          placeholder="Select a Short Comment"
        />
      </div>
    </div>

    <template #footer>
      <div v-if="interviewError" class="p-invalid p-text-left p-pb-5">
        {{ interviewErrorMessage }}
      </div>

      <Button
        label="Cancel"
        icon="pi pi-times"
        class="p-button-text"
        @click="cancelInterviewInfo()"
      />
      <Button label="Save" icon="pi pi-check" @click="saveInterviewInfo()" />
    </template>
  </Dialog>
</template>


<script>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import Calendar from "primevue/calendar";
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import axios from "axios";
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import MultiSelect from "primevue/multiselect";
import Dialog from "primevue/dialog";
import Textarea from "primevue/textarea";
import Badge from "primevue/badge";
export default {
  components: {
    // HelloWorld
  },
  setup() {
    const expandedRows = ref([]);
    const employees = ref([]);
    const dt = ref(null);

    const accessToken = ref("");
    const message = ref("");
    const filters1 = ref(null);
    const selectedCandidate = ref(null);
    const loading = ref(true);
    const route = useRouter();
    const positionTypes = ref([]);
    const workShow = ref("none");
    const delShow = ref("none");
    const firstShow = ref("none");
    const compShow = ref("none");
    const chiNameShow = ref("none");
    const genderShow = ref("none");
    const availabilityShow = ref("none");
    const rwChineShow = ref("none");
    const ChineseSpeakers = ref([]);

    const interviewDisplay = ref(false);

    const interviewRatingOptions = ref([
      "Will Interview",
      "Not Interrested",
      "Will Call Back",
      "Not Qualified",
      "Sent Offer",
      "Hired",
    ]);
    const interviewError = ref(false);
    const interviewErrorMessage = ref("");
    const interviewContacted = ref(false);

    const selectedColumns = ref([
      { colName: "Working Time", value: "workShow", dataKey: 1 },
      { colName: "Delete", value: "delShow", dataKey: 2 },
      { colName: "Firstname", value: "firstShow", dataKey: 3 },
      { colName: "Chinese Name", value: "chiNameShow", dataKey: 4 },
      { colName: "Computer", value: "compShow", dataKey: 5 },
      { colName: "Gender", value: "genderShow", dataKey: 6 },
      { colName: "Availabitlity", value: "availabilityShow", dataKey: 7 },
      { colName: "R/W Chinese", value: "rwChineShow", dataKey: 8 },
    ]);
    const hideColNames = ref([
      { colName: "Working Time", value: "workShow", dataKey: 1 },
      { colName: "Delete", value: "delShow", dataKey: 2 },
      { colName: "Firstname", value: "firstShow", dataKey: 3 },
      { colName: "Chinese Name", value: "chiNameShow", dataKey: 4 },
      { colName: "Computer", value: "compShow", dataKey: 5 },
      { colName: "Gender", value: "genderShow", dataKey: 6 },
      { colName: "Availabitlity", value: "availabilityShow", dataKey: 7 },
      { colName: "R/W Chinese", value: "rwChineShow", dataKey: 8 },
    ]);
    const candidate = ref({
      interviewinfo: {
        comments: "",
        ratings: "",
        contacted: false,
      },
    });
    accessToken.value = localStorage.getItem("token");
    positionTypes.value = ["Admin/Clerk", "Teacher", "Homework Teacher"];
    ChineseSpeakers.value = [
      { Type: "Chinese Speakers", Value: "Yes" },
      { Type: "Non-Chinese", Value: "No" },
    ];
    if (accessToken.value == null) {
      route.push({ path: "/login" });
    }

    initFilters();

    onMounted(() => {
      getEmployees();
      loading.value = false;
    });

    function cancelInterviewInfo() {
      interviewContacted.value = false;
      interviewDisplay.value = false;
      interviewError.value = false;
      interviewErrorMessage.value = "";
    }

    function saveInterviewInfo() {
      if (
        candidate.value.interviewinfo.comments != "" &&
        candidate.value.interviewinfo.ratings != ""
      ) {
        candidate.value.interviewinfo.contacted = true;
        interviewDisplay.value = false;
        interviewError.value = false;
        interviewErrorMessage.value = "";

        const data = {
          candidate: candidate.value,
        };

        // console.log(data);

        const api = `https://api.tuisyen.org/api/candidates/${data.candidate.id}`;
        // const api = `http://localhost:4070/api/candidates/${data.candidate.id}`;
        axios
          .put(api, data, {
            headers: {
              Authorization: `${accessToken.value}`,
            },
          })
          .then(() => {
            // console.log(response);
            // employees.value = response.data.candidates;
            getEmployees();
          })
          .catch((err) => {
            if (err.response.status == 403) {
              route.push({ path: "/login" });
            }
            console.log(err);
          });
      } else {
        interviewError.value = true;
        interviewErrorMessage.value = "Pleases fill both boxes or press cancel";
      }
    }

    function onRowSelect(event) {
      getCandidate(event.data.id);
      interviewDisplay.value = true;
      // message.value = event.data.id;
    }
    function onRowUnselect(event) {
      message.value = event.data.id;
    }
    function hideColumns(value) {
      workShow.value = "";
      delShow.value = "";
      firstShow.value = "";
      compShow.value = "";
      chiNameShow.value = "";
      genderShow.value = "";
      availabilityShow.value = "";
      rwChineShow.value = "";

      value.forEach(myFunction);

      function myFunction(item) {
        if (item.dataKey == 1) workShow.value = "none";
        if (item.dataKey == 2) delShow.value = "none";
        if (item.dataKey == 3) firstShow.value = "none";
        if (item.dataKey == 4) chiNameShow.value = "none";
        if (item.dataKey == 5) compShow.value = "none";
        if (item.dataKey == 6) genderShow.value = "none";
        if (item.dataKey == 7) availabilityShow.value = "none";
        if (item.dataKey == 8) rwChineShow.value = "none";
      }
    }

    function clearFilter() {
      initFilters();
    }

    function initFilters() {
      filters1.value = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        position: {
          value: null,
          matchMode: FilterMatchMode.EQUALS,
        },
        appliedDate: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
        },
        speakchinese: {
          value: null,
          matchMode: FilterMatchMode.EQUALS,
        },
      };
    }

    function DeleteCandidate(id) {
      const api = `https://api.tuisyen.org/api/candidates/${id}`;
      axios
        .delete(api, {
          headers: {
            Authorization: `${accessToken.value}`,
          },
        })
        .then(() => {
          getEmployees();
        });
    }

    function exportCSV() {
      dt.value.exportCSV();
    }

    function formatDate(appliedDate) {
      // this one includes time , but is longer
      //  return  new Date(appliedDate).toLocaleString();
      return new Date(appliedDate).toLocaleDateString("en-MY", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
    }

    function calculateAge(birthday) {
      // birthday is a date
      var ageDifMs = Date.now() - new Date(birthday).getTime();
      var ageDate = new Date(ageDifMs); // miliseconds from epoch
      return Math.abs(ageDate.getUTCFullYear() - 1970);
    }
    function getEmployees() {
      // const api = "http://localhost:4070/api/candidates";
      const api = "https://api.tuisyen.org/api/candidates";

      axios
        .get(api, {
          headers: {
            Authorization: `${accessToken.value}`,
          },
        })
        .then((response) => {
          // console.log(response);
          employees.value = response.data.candidates;
        })
        .catch((err) => {
          if (err.response.status == 403) {
            route.push({ path: "/login" });
          }
          console.log(err);
        });
    }

    function getCandidate(id) {
      // const api = `http://localhost:4070/api/candidates/${id}`;
      const api = `https://api.tuisyen.org/api/candidates/${id}`;
      axios
        .get(api, {
          headers: {
            Authorization: `${accessToken.value}`,
          },
        })
        .then((response) => {
          // console.log(response.data.candidate);
          candidate.value = response.data.candidate;
        })
        .catch((err) => {
          if (err.response.status == 403) {
            route.push({ path: "/login" });
          }
          console.log(err);
        });
    }

    // function expandAll() {
    //   expandedRows.value = employees.value.filter((p) => p.id);
    // }
    function collapseAll() {
      expandedRows.value = null;
    }
    return {
      interviewErrorMessage,
      interviewError,
      interviewRatingOptions,
      saveInterviewInfo,
      cancelInterviewInfo,
      chiNameShow,
      genderShow,
      availabilityShow,
      rwChineShow,
      workShow,
      delShow,
      firstShow,
      compShow,
      employees,
      candidate,
      DataTable,
      Dropdown,
      Calendar,
      InputText,
      Column,
      onMounted,
      Button,
      exportCSV,
      dt,
      calculateAge,
      formatDate,
      expandedRows,
      collapseAll,
      clearFilter,
      loading,
      filters1,
      DeleteCandidate,
      positionTypes,
      MultiSelect,
      Badge,
      hideColumns,
      selectedColumns,
      hideColNames,
      selectedCandidate,
      ChineseSpeakers,
      Dialog,
      onRowUnselect,
      onRowSelect,
      interviewDisplay,
      Textarea,
    };
  },
};
</script>

<style scoped>
.card {
  text-align: center;
  margin-top: 10px;
}

a:link {
  color: rgb(124, 50, 0);
}

/* visited link */
a:visited {
  color: rgb(199, 181, 102);
}

.app-container {
  text-align: center;
  margin-top: 10px;
}
body #app .p-button {
  margin-left: 0.2em;
}
/* .p-datatable table {
  table-layout: auto;
} */
</style>
